import { Session } from '../../lib/session';

export const ActionTypes = {
    SET_SESSION: 'SET_SESSION',
    CLEAR_SESSION: 'CLEAR_SESSION',
};

interface State {
    session?: Session;
}
const initialState: State = {};

export const setSession = session => {
    return {
        type: ActionTypes.SET_SESSION,
        session: session,
    };
};

export const clearSession = () => {
    return {
        type: ActionTypes.CLEAR_SESSION,
    };
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CLEAR_SESSION:
            const { session: removed, ...otherState } = state;
            return { ...otherState };
        case ActionTypes.SET_SESSION:
            return { ...state, session: action.session };
        default:
            return state;
    }
};
